<template>
  <div>
    <nav class="pill-tabs">
      <div v-for="tab in tabs" :key="tab.key" class="pill-tabs__item">
        <a
          class="pill-tabs__link"
          :class="{ 'pill-tabs__link--active': tab.key === selected }"
          @click="setTab(tab.key)"
        >
          <i class="uil" :class="tab.ico"></i>
          {{ tab.name }}
        </a>
      </div>
    </nav>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  methods: {
    setTab(tab) {
      this.$emit("selected", tab);
    },
  },
};
</script>
